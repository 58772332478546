import React from "react";
import { Icon, Grid } from "semantic-ui-react";

import SEO from "../components/seo";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Grid columns={1}>
      <Grid.Row textAlign="center">
        <Grid.Column>
          <h1>NOT FOUND</h1>
          <Icon name="frown outline" size="massive" />
          <p>You just hit a route that doesn&#39;t exist...</p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Layout>
);

export default NotFoundPage;
